<template>
    <v-main>
        <section id="hero">
            <v-row no-gutters>
                <v-container
                    id="policy"
                    tag="section"
                >
                    <div style="text-align: center">
                        <img
                            src="/img/LogoLifePlus.png"
                            alt=""
                            style="width: 100px"
                        >
                    </div>

                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            style="max-width: 978px"
                        >
                            <v-card
                                class="v-card-profile v-card--material pa-3"
                            >
                                <div class="d-flex grow flex-wrap">
                                    <v-card-text>
                                        <h2 class="mt-5 mb-10 black--text text-uppercase text-center">
                                            ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </h2>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            1 ОБЩИЕ ПОЛОЖЕНИЯ
                                        </h4>
                                        <p>
                                            Политика обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным законом от 27.07.2006.
                                            №152-ФЗ «О персональных данных» (далее – ФЗ-152).
                                        </p>
                                        <p>
                                            Настоящая Политика определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных в lifeplus
                                            (далее – Оператор) с целью защиты прав и свобод человека и гражданина при обработке его персональных данных,
                                            в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                                        </p>
                                        <p>
                                            В Политике используются следующие основные понятия:
                                            <br>
                                            - автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;
                                            <br>
                                            - блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для
                                            уточнения персональных данных);
                                            <br>
                                            - информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку
                                            информационных технологий и технических средств;
                                            <br>
                                            - обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность
                                            персональных данных конкретному субъекту персональных данных;
                                            <br>
                                            - обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации
                                            или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                                            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
                                            <br>
                                            - оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие
                                            и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке,
                                            действия (операции), совершаемые с персональными данными;
                                            <br>
                                            - персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);
                                            <br>
                                            - предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
                                            <br>
                                            - распространение персональных данных — действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных)
                                            или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации,
                                            размещение в информационно- телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
                                            <br>
                                            - трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства,
                                            иностранному физическому или иностранному юридическому лицу;
                                            <br>
                                            - уничтожение персональных данных — действия, в результате которых невозможно восстановить содержание персональных данных в информационной системе
                                            персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.
                                        </p>
                                        <p>
                                            Компания обязана опубликовать или иным образом обеспечить неограниченный доступ к настоящей Политике обработки персональных данных
                                            в соответствии с ч. 2 ст. 18.1 ФЗ-152.5
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            2 ПРИНЦИПЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </h4>
                                        <p>
                                            2.1 Принципы обработки персональных данных
                                        </p>
                                        <p>
                                            Обработка персональных данных у Оператора осуществляется на основе следующих принципов:
                                            <br>
                                            - законности и справедливой основы;
                                            <br>
                                            - ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;
                                            <br>
                                            - недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;
                                            <br>
                                            - недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;
                                            <br>
                                            - обработки только тех персональных данных, которые отвечают целям их обработки;
                                            <br>
                                            - соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;
                                            <br>
                                            - недопущения обработки персональных данных, избыточных по отношению к заявленным целям их обработки;
                                            <br>
                                            - обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;
                                            <br>
                                            - уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей,
                                            при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.
                                        </p>
                                        <p>
                                            2.2 Условия обработки персональных данных
                                        </p>
                                        <p>
                                            Оператор производит обработку персональных данных при наличии хотя бы одного из следующих условий:
                                            <br>
                                            - обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;
                                            <br>
                                            - обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом,
                                            для осуществления и выполнения, возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;
                                            <br>
                                            - обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица,
                                            подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;
                                            <br>
                                            - обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является
                                            субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных
                                            данных будет являться выгодоприобретателем или поручителем;
                                            <br>
                                            - обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых
                                            целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;
                                            <br>
                                            - осуществляется обработка персональных данных, доступ неограниченного круга лиц, к которым предоставлен субъектом персональных данных либо по его просьбе
                                            (далее — общедоступные персональные данные);
                                            <br>
                                            - осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.
                                        </p>
                                        <p>2.3 Конфиденциальность персональных данных</p>
                                        <p>
                                            Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия
                                            субъекта персональных данных, если иное не предусмотрено федеральным законом.
                                        </p>
                                        <p>2.4 Общедоступные источники персональных данных</p>
                                        <p>
                                            В целях информационного обеспечения у Оператора могут создаваться общедоступные источники персональных данных субъектов, в том числе справочники
                                            и адресные книги. В общедоступные источники персональных данных с согласия субъекта могут включаться его фамилия, имя, отчество, дата и место рождения,
                                            должность, номера контактных телефонов, адрес электронной почты и иные персональные данные, сообщаемые субъектом персональных данных.
                                            Сведения о субъекте должны быть в любое время исключены из общедоступных источников персональных данных по требованию субъекта либо по решению
                                            суда или иных уполномоченных государственных органов.
                                        </p>
                                        <p>2.5 Специальные категории персональных данных</p>
                                        <p>
                                            Обработка Оператором специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов,
                                            религиозных или философских убеждений, состояния здоровья, интимной жизни, допускается в случаях, если:
                                            <br>
                                            - субъект персональных данных дал согласие в письменной форме на обработку таковых;
                                            <br>
                                            - персональные данные сделаны общедоступными субъектом персональных данных;
                                            <br>
                                            - обработка персональных данных осуществляется в соответствии с законодательством о государственной социальной помощи, трудовым законодательством,
                                            законодательством Российской Федерации о пенсиях по государственному пенсионному обеспечению, о трудовых пенсиях;
                                            <br>
                                            - обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных либо жизни,
                                            здоровья или иных жизненно важных интересов других лиц и получение согласия субъекта персональных данных невозможно;
                                            <br>
                                            - обработка персональных данных осуществляется в медико-профилактических целях, в целях установления медицинского диагноза, оказания медицинских
                                            и медико-социальных услуг при условии, что обработка персональных данных осуществляется лицом, профессионально занимающимся медицинской деятельностью
                                            и обязанным в соответствии с законодательством Российской Федерации сохранять врачебную тайну;
                                            <br>
                                            - обработка персональных данных необходима для установления или осуществления прав субъекта персональных данных или третьих лиц, а равно и в
                                            связи с осуществлением правосудия;
                                            <br>
                                            - обработка персональных данных осуществляется в соответствии с законодательством об обязательных видах страхования, со страховым законодательством.
                                        </p>
                                        <p>
                                            Обработка специальных категорий персональных данных должна быть незамедлительно прекращена, если устранены причины, вследствие которых осуществлялась
                                            их обработка, если иное не установлено федеральным законом.
                                        </p>
                                        <p>
                                            Обработка персональных данных о судимости может осуществляться Оператором исключительно в случаях и в порядке, которые определяются в соответствии
                                            с федеральными законами.
                                        </p>
                                        <p>2.6 Биометрические персональные данные</p>
                                        <p>
                                            Сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность
                                            — биометрические персональные данные — могут обрабатываться Оператором только при наличии согласия в письменной форме субъекта.
                                        </p>
                                        <p>2.7 Поручение обработки персональных данных другому лицу</p>
                                        <p>
                                            Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным
                                            законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по поручению Оператора, обязано соблюдать
                                            принципы и правила обработки персональных данных, предусмотренные ФЗ-152.
                                        </p>
                                        <p>2.8 Трансграничная передача персональных данных</p>
                                        <p>
                                            Оператор обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных,
                                            обеспечивается адекватная защита прав субъектов персональных данных, до начала осуществления такой передачи.
                                        </p>
                                        <p>
                                            Трансграничная передача персональных данных на территории иностранных государств, не обеспечивающих адекватной защиты прав субъектов персональных данных,
                                            может осуществляться в случаях:
                                            <br>
                                            - наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных;
                                            <br>
                                            - исполнения договора, стороной которого является субъект персональных данных.
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            3 ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </h4>
                                        <p>3.1 Согласие субъекта персональных данных на обработку его персональных данных</p>
                                        <p>
                                            Субъект персональных данных принимает решение о предоставлении его персональных данных и дает согласие на их обработку свободно, своей волей и в своем
                                            интересе. Согласие на обработку персональных данных может быть дано субъектом персональных данных или его представителем в любой позволяющей подтвердить
                                            факт его получения форме, если иное не установлено федеральным законом.
                                        </p>
                                        <p>
                                            Обязанность предоставить доказательство получения согласия субъекта персональных данных на обработку его персональных данных или доказательство
                                            наличия оснований, указанных в ФЗ-152, возлагается на Оператора.
                                        </p>
                                        <p>3.2 Права субъекта персональных данных</p>
                                        <p>
                                            Субъект персональных данных имеет право на получение у Оператора информации, касающейся обработки его персональных данных, если такое право не ограничено
                                            в соответствии с федеральными законами. Субъект персональных данных вправе требовать от Оператора уточнения его персональных данных, их блокирования или
                                            уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной
                                            цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
                                        </p>
                                        <p>
                                            Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с
                                            помощью средств связи, а также в целях политической агитации допускается только при условии предварительного согласия субъекта персональных данных.
                                        </p>
                                        <p>
                                            Указанная обработка персональных данных признается осуществляемой без предварительного согласия субъекта персональных данных, если администрация сайта
                                            lifeplus.tv не докажет, что такое согласие было получено.
                                        </p>
                                        <p>
                                            Оператор обязан немедленно прекратить по требованию субъекта персональных данных обработку его персональных данных в вышеуказанных целях.
                                        </p>
                                        <p>
                                            Запрещается принятие на основании исключительно автоматизированной обработки персональных данных решений, порождающих юридические последствия
                                            в отношении субъекта персональных данных или иным образом затрагивающих его права и законные интересы, за исключением случаев, предусмотренных
                                            федеральными законами, или при наличии согласия в субъекта персональных данных.
                                        </p>
                                        <p>
                                            Если субъект персональных данных считает, что Оператор осуществляет обработку его персональных данных с нарушением требований ФЗ-152 или иным образом
                                            нарушает его права и свободы, субъект персональных данных вправе обжаловать действия или бездействие Оператора в Уполномоченный орган по защите прав
                                            субъектов персональных данных или в судебном порядке.
                                        </p>
                                        <p>
                                            Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального
                                            вреда в судебном порядке.
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            4 ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                                        </h4>
                                        <p>
                                            Безопасность персональных данных, обрабатываемых Оператором, обеспечивается реализацией правовых, организационных и технических мер, необходимых
                                            для обеспечения требований федерального законодательства в области защиты персональных данных.
                                        </p>
                                        <p>
                                            Для предотвращения несанкционированного доступа к персональным данным Оператором применяются следующие организационно-технические меры:
                                            <br>
                                            - назначение должностных лиц, ответственных за организацию обработки и защиты персональных данных;
                                            <br>
                                            - ограничение состава лиц, имеющих доступ к персональным данным;
                                            <br>
                                            - ознакомление субъектов с требованиями федерального законодательства и нормативных документов Оператора по обработке и защите персональных данных;
                                            <br>
                                            - рганизация учета, хранения и обращения носителей информации;
                                            <br>
                                            - определение угроз безопасности персональных данных при их обработке, формирование на их основе моделей угроз;
                                            <br>
                                            - разработка на основе модели угроз системы защиты персональных данных;
                                            <br>
                                            - проверка готовности и эффективности использования средств защиты информации;
                                            <br>
                                            - разграничение доступа пользователей к информационным ресурсам и программно- аппаратным средствам обработки информации;
                                            <br>
                                            - регистрация и учет действий пользователей информационных систем персональных данных;
                                            <br>
                                            - использование антивирусных средств и средств восстановления системы защиты персональных данных;
                                            <br>
                                            - применение в необходимых случаях средств межсетевого экранирования, обнаружения вторжений, анализа защищенности и средств криптографической защиты информации;
                                            <br>
                                            - организация пропускного режима на территорию Оператора, охраны помещений с техническими средствами обработки персональных данных.
                                        </p>

                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            5 ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                                        </h4>
                                        <p>
                                            Иные права и обязанности Оператора, как оператора персональных данных определяются законодательством Российской Федерации в области персональных данных.
                                            Должностные лица Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную,
                                            административную, гражданско-правовую или уголовную ответственность в порядке, установленном федеральными законами.
                                        </p>
                                    </v-card-text>
                                </div>
                                <v-divider/>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
    </v-main>
</template>

<script>
export default {
    name: "Policy"
}
</script>

<style scoped>

</style>